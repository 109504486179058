.topBtn {
    width: 68px;
    height: 68px;

    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 9999;

    transition: 0.3s;
}

.topBtn img {
    cursor: pointer;
}

@media (max-width: 800px) {
    .topBtn {
        width: 58px;
        height: 58px;
    }
}

@media (max-width: 500px) {
    .topBtn {
        width: 48px;
        height: 48px;
    }
}
