.newsWrap {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

/* newsHeader 시작 */
.newsHeader {
    width: 100%;
    height: 513px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: url(assets/images/news/news_header_bg.png) center;
    background-size: cover;

    text-align: center;
    padding: 0 11.72%;
}

.newsHeaderText1 {
    font-size: 54px;
    font-family: NanumSquareBold;

    color: white;

    margin-bottom: 27px;
}

.newsHeaderText2 {
    font-size: 18px;
    font-family: NanumSquareBold;

    line-height: 32px;

    color: white;
}

/* newsHeader 끝 */
/* newsList 시작 */
.newsLists {
    width: 100%;

    padding: 105px 11.72%;
}

.newsListHeader {
    width: 100%;

    background: #ebebeb;

    border-top: 2px solid #713dc5;

    border-bottom: 2px solid #713dc5;

    display: flex;
    justify-content: space-around;
}

.newsListHeader > div {
    font-size: 18px;
    font-family: NanumSquareBold;

    height: 52px;

    display: flex;
    align-items: center;
}

.newsList {
    width: 100%;

    border-bottom: 2px solid #aaaaaa;

    display: flex;
    justify-content: space-around;
}

.newsListCategory {
    width: 18%;
    line-height: 55px;

    font-size: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.newsListTitle {
    width: 40%;
    line-height: 55px;

    font-size: 18px;
    font-family: NanumSquareBold;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    padding: 0 1%;

    cursor: pointer;
}

.newsListPlaceOfPublication {
    width: 20%;
    line-height: 55px;

    font-size: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.newsListDateOfPublication {
    width: 20%;
    line-height: 55px;

    font-size: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.newsListCategoryText {
    font-family: NanumSquareBold;
    /* 경제 */
    /* border: 2px solid #e04971; */
    /* 의료 */
    /* border: 2px solid #713dc5; */
    /* 지역 */
    /* border: 2px solid #686868; */
    border-radius: 17.5px;
    padding: 4.5px 12px 3.5px;

    line-height: 18px;
}
/* newsList 끝 */

@media (max-width: 800px) {
    .newsHeaderText1 {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .newsHeaderText2 {
        font-size: 12px;
        line-height: 23px;
    }

    .newsListHeader {
        display: none;
    }

    .newsList0 {
        border-top: 2px solid #aaaaaa;
    }

    .newsList {
        flex-wrap: wrap;

        padding: 12px 20px;
    }

    .newsListCategory {
        width: 100%;

        justify-content: flex-start;
    }

    .newsListCategory {
        height: 40px;
    }

    .newsListCategoryText {
        font-size: 12px;
        border: 1px solid #e04971;
    }

    .newsListTitle {
        width: 100%;
        height: 56px;

        line-height: 29px;

        font-size: 15px;

        white-space: inherit;

        padding: 0;
    }

    .newsListPlaceOfPublication {
        width: 50%;

        font-size: 12px;
        line-height: 40px;

        justify-content: flex-start;
    }

    .newsListDateOfPublication {
        width: 50%;

        font-size: 12px;
        line-height: 40px;

        justify-content: flex-end;
    }
}

@media (max-width: 500px) {
}
