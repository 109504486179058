.MainDemoWrap {
    width: 100%;
    height: 490px;

    padding: 0 13.5%;

    background: url(assets/images/main/main_demo_bg.png) center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.MainDemoText1 {
    width: 74.7%;

    margin-bottom: 22px;

    font-size: 28px;
    font-family: NanumSquareBold;

    line-height: 45px;

    color: white;
}

.MainDemoText2 {
    width: 74.7%;

    font-size: 18px;
    line-height: 30px;

    color: #a2a2a2;
}

.MainDemoBtn {
    width: 200px;
    height: 48px;

    font-size: 18px;
    font-family: NanumSquareBold;

    color: white;

    background: #4b2587;

    border-radius: 24px;

    margin-top: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

@media (max-width: 800px) {
    .MainDemoText1 {
        font-size: 22px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    .MainDemoText2 {
        font-size: 15px;
        line-height: 26px;
    }

    .MainDemoBtn {
        width: 160px;
        height: 44px;

        font-size: 17px;

        border-radius: 22px;
    }
}

@media (max-width: 500px) {
    .MainDemoText1 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 12px;
    }

    .MainDemoText2 {
        font-size: 12.5px;
        line-height: 20px;
    }

    .MainDemoBtn {
        width: 145px;
        height: 38px;
        font-size: 13.5px;
        border-radius: 19px;
    }
}
