@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: NanumSquare;
  src: url(./assets/fonts/NanumSquareL.otf);
}
@font-face {
  font-family: NanumSquareBold;
  src: url(./assets/fonts/NanumSquareB.otf);
}
@font-face {
  font-family: NanumSquareEB;
  src: url(./assets/fonts/NanumSquareEB.otf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: NanumSquare;
  cursor: default;

  word-break: keep-all;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  width: auto;
  height: 100%;
}

.wrapper {
  width: 100%;

  position: relative;
}

.btnAnimation {
  @apply transition-all hover:bg-opacity-80 active:scale-95;
}
