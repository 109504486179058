.MainNewsWrap {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

/* MainNewsTitle */

.MainNewsTitle {
    width: 74.7%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin-top: 105px;
    margin-bottom: 36px;
}

.MainNewsTitleText1 {
    font-size: 38px;
    font-family: NanumSquareEB;

    line-height: 54px;

    color: #703dc5;

    margin-bottom: 16.5px;
}

.MainNewsTitleText2 {
    font-size: 20px;

    line-height: 37px;
}

/* MainNewsSlide */

.MainNewsSlide {
    width: 85%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.MainNewsFullView {
    margin: 15px 0;
    padding: 0 12px;

    display: flex;
    justify-content: flex-end;
}

.MainNewsFullViewBtn {
    font-size: 21px;
    font-family: NanumSquareBold;

    color: #8f8f8f;

    cursor: pointer;
}

.MainNewsBtn {
    width: 27px;
    height: 45px;
}

.MainNewsBtn img {
    cursor: pointer;
}

.prev {
    margin-right: 32px;
}

.next {
    margin-left: 32px;
}

.MainNewsCards {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.MainNewsCardWrap {
    width: 81%;

    overflow: hidden;
}

.MainNewsCardArea {
    display: flex;
}

.MainNewsCard {
    width: 399px;
    height: 460px;

    border-radius: 15px;
    border: 1px solid #919191;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);

    margin: 0 19px;
    padding: 20px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.MainNewsCard:hover {
    border: 2px solid #713dc5;
}

.MainNewsCard > div {
    width: 88.63%;
}

.MainNewsCardImage {
    height: 196px;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}

.MainNewsCardTitle {
    height: 75px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
}

.MainNewsCardTitle p {
    font-size: 23px;
    font-family: NanumSquareBold;

    line-height: 37px;
}

.MainNewsCardText {
    height: 110px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
}

.MainNewsCardText p {
    font-size: 15px;

    line-height: 28px;
}

.MainNewsCardLine {
    height: 2px;
    background: #713dc5;
}

/* MainNewsPapers */
.MainNewsPreviews {
    width: calc(85% + 60px);

    margin-bottom: 64px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.MainNewsPapers,
.MainNewsReport {
    width: 74.7%;
    /* width: 650px; */

    margin: 69px 30px 0 30px;
}

.MainNewsPapersTitle,
.MainNewsReportTitle {
    width: 100%;

    margin-bottom: 25px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MainNewsPapersTitleText,
.MainNewsReportTitleText {
    font-size: 30px;
    font-family: NanumSquareBold;
}

.MainNewsPapersTitleBtn,
.MainNewsReportTitleBtn {
    font-size: 21px;
    font-family: NanumSquareBold;

    color: #8f8f8f;
}

.MainNewsReportTitleBtn {
    cursor: pointer;
}

.MainNewsPapersList,
.MainNewsReportList {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 19px;
}

.MainNewsReportListTitle a {
    text-decoration: none;
    color: initial;
}

.MainNewsReportListTitle a:hover {
    font-family: NanumSquareBold;
    cursor: pointer;
}

.MainNewsPapersListTexts,
.MainNewsReportListTexts {
    width: calc(100% - 132px);
    display: flex;
    align-items: center;
}

.MainNewsPapersListCategory,
.MainNewsReportListCategory {
    padding: 6px 19px;

    border-radius: 19px;

    font-size: 21px;
    font-family: NanumSquareBold;

    margin-right: 15px;
}

.MainNewsPapersListTitle,
.MainNewsReportListTitle {
    font-size: 21px;
    font-family: NanumSquare;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.MainNewsPapersListDate,
.MainNewsReportListDate {
    width: 117px;
    font-size: 21px;
    font-family: NanumSquare;
}

/* MainNewsTexts */

.MainNewsTexts {
    width: calc(85% - 60px);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: 105px;
}

.MainNewsText1 {
    width: 190px;

    padding-top: 22px;
}

.MainNewsText1Top {
    font-size: 30px;
    font-family: NanumSquareBold;

    margin-bottom: 13px;
}

.MainNewsText1Bottom {
    font-size: 18px;
    font-family: NanumSquareBold;

    color: #8f8f8f;
}

.MainNewsTextWrap {
    width: calc(100% - 220px);
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.MainNewsText {
    font-size: 27px;

    line-height: 49px;

    margin-right: 20px;

    padding: 22px 0;
}

.MainNewsText:last-of-type {
    width: 100%;
}

.MainNewsText span {
    font-family: NanumSquareBold;
    background: linear-gradient(
        to bottom,
        transparent 66%,
        rgba(113, 61, 197, 0.22) 34%
    );
}

@media (max-width: 800px) {
    .prev {
        margin-right: 15px;
    }

    .next {
        margin-left: 15px;
    }

    .MainNewsCard {
        padding: 12px 0;
    }

    .MainNewsCard {
        width: 300px;
        height: 345.86px;
    }

    .MainNewsCardTitle {
        height: 57px;
    }

    .MainNewsCardTitle p {
        font-size: 16px;
        line-height: 26px;
    }

    .MainNewsCardText {
        height: 90px;
    }

    .MainNewsCardText p {
        font-size: 12px;
        line-height: 22px;
    }

    .MainNewsCardImage {
        height: 166.6px;
    }

    .MainNewsFullViewBtn {
        font-size: 18px;
    }

    .MainNewsPapers,
    .MainNewsReport {
        width: calc(85% + 60px);

        margin: 55px 30px 0 30px;
    }

    .MainNewsPapersTitleText,
    .MainNewsReportTitleText {
        font-size: 22px;
    }

    .MainNewsPapersTitleBtn,
    .MainNewsReportTitleBtn {
        font-size: 15px;
    }

    .MainNewsPapersList,
    .MainNewsReportList {
        margin-bottom: 10px;
    }

    .MainNewsPapersListTexts,
    .MainNewsReportListTexts {
        width: calc(100% - 98px);
    }

    .MainNewsPapersListCategory,
    .MainNewsReportListCategory {
        padding: 5px 12px;

        border-radius: 14.5px;

        font-size: 15px;

        margin-right: 13px;
    }

    .MainNewsPapersListTitle,
    .MainNewsReportListTitle {
        font-size: 15px;
    }

    .MainNewsPapersListDate,
    .MainNewsReportListDate {
        width: 83px;
        font-size: 15px;
    }

    .MainNewsTexts {
        width: 85%;
    }

    .MainNewsTextWrap {
        margin-top: 0;
    }

    .MainNewsText1Top {
        font-size: 22px;
    }

    .MainNewsText1Bottom {
        font-size: 15px;
    }

    .MainNewsText {
        font-size: 16px;

        line-height: 33px;

        padding: 22px 0;
    }
}

@media (max-width: 500px) {
    .MainNewsTitleText1 {
        font-size: 25px;
    }

    .MainNewsTitleText2 {
        font-size: 14px;

        line-height: 22px;
    }

    .MainNewsBtn {
        width: 15px;
        height: 25px;
    }

    .prev {
        margin-right: 4px;
    }

    .next {
        margin-left: 4px;
    }

    .MainNewsCard {
        width: 240px;
        height: 276.69px;
    }

    .MainNewsCardImage {
        height: 122.84px;
    }

    .MainNewsCardTitle {
        height: 40px;
    }

    .MainNewsCardTitle p {
        font-size: 14px;
        line-height: 20px;
    }

    .MainNewsCardText {
        height: 64px;
    }

    .MainNewsCardText p {
        font-size: 10px;
        line-height: 16px;
    }

    .MainNewsFullViewBtn {
        font-size: 16px;
    }

    .MainNewsPreviews {
        width: calc(85% + 30px);

        margin-bottom: 48px;
    }

    .MainNewsPapers,
    .MainNewsReport {
        width: calc(85% + 30px);

        margin: 55px 15px 0 15px;
    }

    .MainNewsPapersTitleText,
    .MainNewsReportTitleText {
        font-size: 17px;
    }

    .MainNewsPapersTitleBtn,
    .MainNewsReportTitleBtn {
        font-size: 12px;
    }

    .MainNewsPapersList,
    .MainNewsReportList {
        margin-bottom: 10px;
    }

    .MainNewsPapersListTexts,
    .MainNewsReportListTexts {
        width: calc(100% - 82px);
    }

    .MainNewsPapersListCategory,
    .MainNewsReportListCategory {
        padding: 3.5px 8px 2.5px;

        border-radius: 10.5px;

        font-size: 12px;

        margin-right: 13px;
    }

    .MainNewsPapersListTitle,
    .MainNewsReportListTitle {
        font-size: 12px;
    }

    .MainNewsPapersListDate,
    .MainNewsReportListDate {
        width: 67px;
        font-size: 12px;
    }

    .MainNewsText1Top {
        font-size: 17px;
    }

    .MainNewsText1Bottom {
        font-size: 12px;
    }

    .MainNewsText1 {
        width: 120px;
    }

    .MainNewsTextWrap {
        width: calc(100% - 141px);
    }

    .MainNewsText {
        font-size: 13px;
        line-height: 31px;

        padding: 15px 0;
    }
}
