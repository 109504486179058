.recruitmentWrap {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

/* recruitmentHeader 시작 */
.recruitmentHeader {
    width: 100%;
    height: 513px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: url(assets/images/recruitment/recruitment_header_bg.png) center;
    background-size: cover;

    text-align: center;
    padding: 0 11.72%;
}

.recruitmentHeaderText1 {
    font-size: 54px;
    font-family: NanumSquareBold;

    color: white;

    margin-bottom: 27px;
}

.recruitmentHeaderText2 {
    font-size: 18px;
    font-family: NanumSquareBold;

    line-height: 32px;

    color: white;
}

/* recruitmentHeader 끝 */
/* recruitmentSection1 시작 */
.recruitmentSection1 {
    width: 100%;

    padding: 127px 11.72% 182px;
    margin: 105px 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: url(assets/images/recruitment/recruitment_section1_bg.png)
        center;
    background-size: cover;
}

.recruitmentSection1Text1,
.recruitmentSection1Text2 {
    width: 100%;

    font-size: 48px;
    font-family: NanumSquareBold;

    line-height: 68px;

    color: white;
}

.recruitmentSection1CardWrap {
    width: 100%;

    margin-top: 89px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.recruitmentSection1Card {
    width: 425px;
    height: 306px;

    padding: 0 42px;
    margin: 20px 10px;

    background: white;
    border-radius: 13px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.recruitmentSection1CardText1 {
    margin-bottom: 35px;

    font-size: 35px;
    font-family: NanumSquareBold;

    color: #713dc5;
}

.recruitmentSection1CardText2 {
    font-size: 18px;

    line-height: 35px;
}
/* recruitmentSection1 끝 */
/* recruitmentSection2 시작 */
.recruitmentSection2 {
    width: 100%;

    margin: 75px 0;
    padding: 0 11.72%;

    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    align-items: center;
}

.recruitmentSection2 > div {
    margin: 30px 0;
}
.recruitmentSection2Texts {
    width: 489px;
}

.recruitmentSection2TextArea1Text {
    width: 100%;
    height: 55px;

    font-size: 18px;
    line-height: 35px;

    border-bottom: 2px solid #aaaaaa;

    display: flex;
    align-items: center;
}

.recruitmentSection2TextArea1Text:first-of-type {
    border-top: 2px solid #aaaaaa;
}

.recruitmentSection2TextArea1Text::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;

    margin: 0 12px 2px 17px;
    margin-bottom: 2px;
    margin-right: 12px;

    background: #713dc5;

    border-radius: 50%;
}

.recruitmentSection2TextArea1 {
    margin-bottom: 42px;
}

.recruitmentSection2TextArea2 {
    font-size: 20px;

    line-height: 37px;
}

.recruitmentSection2Img {
    width: 838px;
}

.recruitmentSection2Img img {
    width: 100%;
    height: auto;
}
/* recruitmentSection2 끝 */
/* recruitmentSection3 시작 */
.recruitmentSection3 {
    width: 100%;

    padding: 105px 0;

    background: #ebebeb;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.recruitmentSection3Title {
    width: 100%;

    padding: 0 11.72%;

    font-size: 38px;
    font-family: NanumSquareEB;

    color: #713dc5;
}

.recruitmentSection3Area1 {
    width: 100%;

    padding: 0 11.72%;

    margin: 88px 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.recruitmentSection3Area1Circle {
    width: 195px;
    height: 195px;

    margin: 16px 8px;

    border-radius: 50%;
    border: 19px solid rgba(113, 61, 197, 0.15);

    background: white;

    font-size: 18px;
    font-family: NanumSquareBold;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.recruitmentSection3Area2 {
    width: 100%;

    padding: 32px 11.72% 0;
}

.recruitmentSection3Area2Title {
    padding: 15px 0;
    margin-bottom: 31px;

    font-size: 23px;
    font-family: NanumSquareBold;

    border-bottom: 2px solid #aaaaaa;
}

.recruitmentSection3Area2Text {
    font-size: 21px;
    line-height: 24px;

    color: #979797;

    margin-bottom: 28px;
    padding-left: 17px;
}
/* recruitmentSection3 끝 */

@media (max-width: 1743px) {
    .recruitmentSection1CardWrap {
        flex-direction: column;
        margin-top: 78px;
    }

    .recruitmentSection1 {
        background: #1b0c2c;
    }
}

@media (max-width: 800px) {
    .recruitmentHeaderText1 {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .recruitmentHeaderText2 {
        font-size: 12px;
        line-height: 23px;
    }

    .recruitmentSection1Text1,
    .recruitmentSection1Text2 {
        margin-left: 25px;
    }

    .recruitmentSection1 {
        padding: 125px 25px;
    }

    .recruitmentSection2 {
        padding: 0 50px;
    }

    .recruitmentSection2Img {
        width: 100%;
    }

    .recruitmentSection3Area2 {
        padding: 0 50px;
    }

    .recruitmentSection3Area2Text {
        font-size: 15px;
        line-height: 25px;
    }
}

@media (max-width: 500px) {
    .recruitmentSection1CardWrap {
        margin-top: 34px;
    }

    .recruitmentSection1Text1,
    .recruitmentSection1Text2 {
        font-size: 25px;

        line-height: 35px;
    }

    .recruitmentSection1Card {
        width: 305px;
        height: 182px;

        padding: 0 31px;
    }

    .recruitmentSection1CardText1 {
        font-size: 20px;
    }

    .recruitmentSection1CardText2 {
        font-size: 11px;

        line-height: 22px;
    }

    .recruitmentSection2 {
        padding: 0 35px;
    }

    .recruitmentSection2Texts {
        width: 100%;
    }

    .recruitmentSection2TextArea1Text {
        height: 38px;

        font-size: 12px;
        line-height: 35px;
    }

    .recruitmentSection2TextArea1 {
        margin-bottom: 34px;
    }

    .recruitmentSection2TextArea2 {
        font-size: 12px;

        line-height: 23px;
    }

    .recruitmentSection3Title {
        font-size: 25px;
    }

    .recruitmentSection3Area2 {
        padding: 0 42px;
    }

    .recruitmentSection3Area2Title {
        font-size: 20px;
    }

    .recruitmentSection3Area2Text {
        font-size: 12px;
        line-height: 21px;
    }
}
