.MainCooperativeWrap {
    width: 100%;

    padding: 0 7.5%;

    background: #ebebeb;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.MainCooperativeText {
    font-size: 18px;
    font-family: NanumSquareBold;
    line-height: 26px;

    margin-right: 20px;
}

.MainCooperativeBtn {
    height: 18.7px;

    margin: 0 20px;
}

.MainCooperativeBtn img {
    cursor: pointer;
}

.MainCooperativeSliderWrap {
    width: calc(100% - 139px);
    min-width: 334px;

    display: flex;
    align-items: center;
    justify-content: space-around;
}

.MainCooperativeImgsWrap {
    width: calc(100% - 84px);
    min-width: 250px;
    display: flex;
    align-items: center;

    overflow: hidden;
}

.MainCooperativeImgs {
    display: flex;
    align-items: center;
}

.MainCooperativeImg {
    width: 250px;

    padding: 0 25px;
}

.MainCooperativeImg img {
    width: 100%;
    height: auto;

    cursor: pointer;
}

@media (max-width: 549px) {
    .MainCooperativeText {
        width: 100%;

        margin-right: 0;
        margin-top: 15px;
        font-size: 15px;
    }
}

.MainCooperativeImg img{
    width: 173px;
    height: auto;
}