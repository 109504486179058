.MainIntroWrap {
    width: 100%;
}
.MainIntroVideo {
    width: 100%;
    height: calc(100vh - 176px);

    background: black;

    position: relative;
}

.MainIntroVideo video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.MainIntroVideoText {
    width: 74.7%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MainIntroVideoText1 {
    text-align: center;

    margin-bottom: 30px;
}

.MainIntroVideoText1 p {
    line-height: 8vw;

    font-size: 5vw;
    font-family: NanumSquareEB;

    color: white;
}

.MainIntroVideoText2 {
    text-align: center;
}

.MainIntroVideoText2 p {
    line-height: 32px;

    font-size: 18px;
    font-family: NanumSquareBold;

    color: white;
}

.MainIntroShortcut {
    width: 100%;
    height: 87px;

    background: #210c44;

    display: flex;
    justify-content: center;
    align-items: center;
}

.MainIntroShortcutText {
    color: #d7dae1;

    font-size: 20px;

    line-height: 26px;

    font-family: NanumSquareBold;
}

.MainIntroShortcutLine {
    width: 1px;
    height: 17px;

    background: white;

    margin: 0 13px;
}

.MainIntroShortcutBtn {
    color: #f4e727;

    font-size: 20px;

    line-height: 23px;

    cursor: pointer;
}

@media (max-width: 800px) {
    .MainIntroVideoText1 p {
        line-height: 10vw;

        font-size: 7vw;
    }
    .MainIntroVideoText2 p {
        line-height: 30px;

        font-size: 14px;
    }

    .MainIntroShortcutText,
    .MainIntroShortcutBtn {
        font-size: 12px;

        line-height: 13px;
    }
}

@media (max-width: 500px) {
    .MainIntroVideo {
        height: calc(100vh - 154px);
    }
    .MainIntroVideoText2 p {
        line-height: 20px;

        font-size: 11.5px;
    }
}
