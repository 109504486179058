.customerWrap {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

/* customerHeader 시작 */
.customerHeader {
    width: 100%;
    height: 513px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: url(assets/images/customer/customer_header_bg.png) center;
    background-size: cover;

    text-align: center;

    padding: 0 11.72%;
}

.customerHeaderText1 {
    font-size: 54px;
    font-family: NanumSquareBold;

    color: white;

    margin-bottom: 27px;
}

.customerHeaderText2 {
    font-size: 18px;
    font-family: NanumSquareBold;

    line-height: 32px;

    color: white;
}

/* customerHeader 끝 */
/* customerSection1 시작 */
.customerSection1 {
    width: 100%;

    padding: 0 11.72%;
}
.customerSection1Header {
    width: 100%;

    padding: 105px 41px 70px;

    background: #ebebeb;

    border-bottom: 2px solid #713dc5;
}

.customerSection1HeaderText1 {
    margin-bottom: 31px;
}

.customerSection1HeaderText1 p {
    font-size: 35px;
    font-family: NanumSquareBold;

    line-height: 54px;

    color: #713dc5;
}

.customerSection1HeaderText2 {
    font-size: 22px;
    font-family: NanumSquareBold;

    line-height: 39px;
}

.customerInput {
    width: 100%;

    border-bottom: 2px solid #aaaaaa;

    display: flex;
    align-items: center;
}

.customerInput label {
    width: 195px;
    height: 56px;

    padding-left: 41px;

    font-size: 21px;

    color: #686868;
    font-family: NanumSquareBold;

    display: flex;
    align-items: center;
}

.customerInput input {
    width: calc(100% - 195px);
    height: 40px;

    padding: 0 13px;

    border: 1px solid #cbcbcb;

    font-size: 21px;
    font-family: NanumSquareBold;
}

.customerInput input::placeholder {
    color: #bdbdbd;
}

.customerInput:last-of-type {
    align-items: flex-start;
}

.customerInput textarea {
    width: calc(100% - 195px);
    height: 397px;

    padding: 7px 13px;
    margin: 8px 0;

    border: 1px solid #cbcbcb;

    font-size: 21px;
    font-family: NanumSquareBold;
}

.customerInput textarea::placeholder {
    color: #bdbdbd;
}

.customerSection1Consent {
    margin: 64px 0 0px;
}

.customerSection1ConsentTitle {
    margin-bottom: 18px;

    font-size: 21px;
    font-family: NanumSquareBold;

    line-height: 26px;

    color: #686868;
}

.customerSection1ConsentContent {
    border: 1px solid #aaaaaa;

    margin-bottom: 55px;
}

.customerSection1ConsentContentText {
    width: 100%;

    padding: 34px 60px;

    background: #f9f9f9;

    font-size: 18px;

    line-height: 29px;

    color: #686868;
}

.customerSection1ConsentContentText > div {
    width: 100%;
    margin-bottom: 28px;
}

.customerSection1ConsentContentText > div:last-of-type {
    margin-bottom: 0;
}

.customerSection1ConsentContentText > div p:first-of-type {
    font-family: NanumSquareBold;
}
.customerSection1ConsentContentText > div p:last-of-type {
    margin-left: 12px;
}

.customerSection1ConsentContentBtn {
    width: 100%;
    height: 69px;

    padding-left: 60px;

    display: flex;
    align-items: center;
}

.customerSection1ConsentContentBtn input {
    width: 19px;
    height: 19px;
    margin: 0 12px 2px 0;

    cursor: pointer;
}

.ConsentCheck:checked {
    accent-color: #713dc5;
}

.customerSection1ConsentContentBtn label {
    font-size: 18px;

    color: #707070;
}

.customerSection1Submission {
    width: 100%;

    display: flex;
    justify-content: center;
}

.customerSection1SubmissionBtn {
    padding: 14px 52px;

    border-radius: 3px;

    font-size: 21px;
    font-family: NanumSquareBold;

    color: white;
}
/* customerSection1 끝 */
/* customerSection2 시작 */
.customerSection2 {
    width: 100%;

    padding: 105px 11.72%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.customerSection2Title {
    width: 100%;

    margin-bottom: 8px;

    font-size: 25px;
    font-family: NanumSquareEB;

    color: #713dc5;
}

.customerSection2Card {
    margin-top: 22px;

    background: #f9f9f9;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    display: flex;
}

.customerSection2Card1 {
    width: max(49%, 638px);

    padding: 30px 64px;
}

.customerSection2Card2 {
    width: max(49%, 638px);

    padding: 30px 64px 68px;
}

.customerSection2Card3 {
    width: 100%;

    padding: 30px 64px;
}

.customerSection2CardTitle {
    width: 155px;

    font-size: 20px;
    font-family: NanumSquareBold;

    line-height: 38px;

    color: #713dc5;
}

.customerSection2CardContent {
    width: calc(100% - 155px);
}

.customerSection2CardContent p {
    font-size: 20px;

    line-height: 38px;
}

.customerSection2CardContent p::before {
    margin: 0 16px 3px 0;
}
/* customerSection2 끝 */
@media (max-width: 1700px) {
    .customerSection2Card1,
    .customerSection2Card2 {
        width: 100%;
    }

    .customerSection2Card1,
    .customerSection2Card2,
    .customerSection2Card3 {
        padding: 30px 64px;
    }
}
@media (max-width: 800px) {
    .customerHeaderText1 {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .customerSection1HeaderText1 p {
        font-size: 29px;
        line-height: 45px;
    }

    .customerSection1HeaderText2 {
        font-size: 20px;
        line-height: 34px;
    }

    .customerHeaderText2 {
        font-size: 12px;
        line-height: 23px;
    }

    .customerSection1 {
        padding: 0;
    }
    .customerSection1Header {
        padding: 105px 50px 70px;
    }

    .customerSection1Input,
    .customerSection1Consent {
        padding: 0 50px;
    }

    .customerInput label {
        width: 145px;
        height: 51px;
        padding-left: 15px;
        font-size: 18px;
    }

    .customerInput input {
        width: calc(100% - 145px);
        height: 36px;
        padding: 0 11px;
        font-size: 18px;
    }

    .customerInput textarea {
        width: calc(100% - 145px);
        height: 328px;
        padding: 7px 11px;
        font-size: 18px;
    }

    .customerSection1ConsentTitle {
        font-size: 18px;
    }

    .customerSection1ConsentContentText {
        padding: 34px 33px;
        font-size: 15px;
        line-height: 27px;
    }

    .customerSection1ConsentContentBtn {
        height: 55px;
        padding-left: 33px;
    }

    .customerSection1SubmissionBtn {
        padding: 11px 44px;
        font-size: 18px;
    }

    .customerSection2 {
        padding: 105px 50px;
    }

    .customerSection2Card1,
    .customerSection2Card2,
    .customerSection2Card3 {
        padding: 25px 24px;
    }

    .customerSection2CardContent p {
        font-size: 16px;
        line-height: 29px;
    }

    .customerSection2CardTitle {
        width: 117px;
        font-size: 18px;
        line-height: 29px;
    }

    .customerSection2CardContent p::before {
        display: inline-block;
        content: "";
        width: 5px;
        height: 5px;
        background: #713dc5;
        border-radius: 50%;
        margin: 0 16px 3px 0;
    }
}

@media (max-width: 500px) {
    .customerSection1Input,
    .customerSection1Consent {
        padding: 0 21px;
    }

    .customerSection1HeaderText1 p {
        font-size: 20px;
        line-height: 35px;
    }

    .customerSection1HeaderText2 {
        font-size: 15px;
        line-height: 23px;
    }

    .customerInput label {
        width: 46px;
        height: 29px;
        padding-left: 0;
        font-size: 11px;
    }

    .customerInput input {
        width: calc(100% - 46px);
        height: 21px;

        padding: 0 7px;

        font-size: 11px;
    }

    .customerInput textarea {
        width: calc(100% - 46px);
        height: 208px;

        padding: 5px 7px;
        margin: 4px 0;

        font-size: 11px;
    }

    .customerSection1Consent {
        margin: 38px 0 0px;
    }

    .customerSection1ConsentContentBtn {
        height: 30px;
        padding-left: 14px;
    }

    .customerSection1ConsentContentBtn input {
        width: 9px;
        height: 9px;
        margin: 0 6px 2px 0;
    }

    .customerSection1ConsentContent {
        margin-bottom: 20px;
    }

    .customerSection1ConsentTitle {
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 10px;
    }

    .customerSection1ConsentContentText {
        padding: 21px 15px;
        font-size: 10px;
        line-height: 13px;
    }

    .customerSection1ConsentContentText > div {
        margin-bottom: 15px;
    }

    .customerSection1ConsentContentBtn label {
        font-size: 10px;
    }

    .customerSection1SubmissionBtn {
        font-size: 13px;

        padding: 6px 14px 7px;
    }

    .customerSection2 {
        padding: 105px 21px;
    }

    .customerSection2Title {
        font-size: 15px;
    }

    .customerSection2CardTitle {
        width: 87px;

        font-size: 12px;

        line-height: 19px;
    }

    .customerSection2CardContent {
        width: calc(100% - 87px);
    }

    .customerSection2Card1,
    .customerSection2Card2,
    .customerSection2Card3 {
        padding: 20px 22px;
    }

    .customerSection2CardContent p {
        font-size: 12px;

        line-height: 19px;
    }

    .customerSection2CardContent p::before {
        margin: 0 16px 1px 0;
    }
}
