.headerBG {
  width: 100%;
  height: 89px;

  background: #1b0c2b;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.16);

  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 99999;
}
.headerWrap {
  width: max(74.7%, 890px);
  height: 100%;

  padding: 0 16px;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLogo {
  height: 33px;
}

.headerLogo img {
  cursor: pointer;
}

.headerButtons {
  width: max(70%, 620px);

  display: flex;
  justify-content: space-between;

  color: white;
  font-size: 16px;
}

.headerNavWrap {
  width: max(70%, 500px);

  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
  align-items: center;
}

.headerNavWrap > div {
  margin: 0 20px;
}

.headerButtons > div > div {
  cursor: pointer;
}

.headerLanBtn {
  display: flex;
  align-items: center;
}

.headerLanBtn > div {
  font-family: NanumSquareBold;
}

.headerLanBtnLine {
  width: 1px;
  height: 15px;

  background: #aba8af;

  margin: 0 15px;
}

.headerNavIcon {
  width: 40px;
  height: 40px;

  display: none;
}

.mobileNavBG {
  width: 100%;
  height: calc(100vh - 89px);

  background: rgba(0, 0, 0, 0.16);

  position: fixed;
  top: 89px;
  left: 0;
}

.mobileNav {
  width: 279px;
  height: calc(100vh - 89px);

  position: absolute;
  top: 89px;
  right: 0;

  overflow: hidden;

  color: white;

  transition: 0.3s;

  background: #1b0c2c;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileNavTop > div {
  padding-left: 28px;

  font-size: 20px;
}

.mobileNavLogos {
  width: 100%;
  height: 56px;

  padding-right: 10px;

  border-bottom: 1px solid #e7e7e7;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileNavLogo {
  height: 20px;
}

.mobileNavClose {
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.mobileNavClose img {
  height: 8px;

  cursor: pointer;
}

.mobileNavDiv {
  width: 100%;
  height: 52px;

  border-bottom: 1px solid rgba(231, 231, 231, 0.45);

  display: flex;
  align-items: center;
}

.mobileNavDivCircle {
  width: 6px;
  height: 6px;

  border-radius: 3px;
  background: #713dc5;

  margin-right: 16px;
}

.mobileNavDivText {
  cursor: pointer;
}

.mobileNavBottom {
  width: 100%;
  height: 52px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileNavBottom > div {
  margin: 0 10px;
}

@media (max-width: 800px) {
  .headerWrap {
    justify-content: center;
  }
  .headerButtons {
    display: none;
  }

  .headerNavIcon {
    display: block;

    position: absolute;
    top: 25px;
    right: 16px;
  }
}

@media (max-width: 500px) {
  .headerBG {
    height: 67px;
  }

  .headerLogo {
    height: 22px;
  }

  .headerNavIcon {
    width: 24px;
    height: 24px;
  }

  .mobileNavBG {
    height: calc(100vh - 67px);
    top: 67px;
  }

  .mobileNav {
    height: calc(100vh - 67px);
    top: 67px;
  }
}
