.newsPageWrap {
    margin-top: 101px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.newsPageWrap > div {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 3.8px;
}

.newsPageBtn {
    width: 26.42px;
    height: 26.42px;

    border-radius: 3px;

    margin: 0 3.8px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-family: NanumSquareBold;

    cursor: pointer;
}

@media (max-width: 800px) {
    .newsPageWrap {
        margin-top: 45px;
    }
}
