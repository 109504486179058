.footerWrap {
    width: 100%;

    background: #220c44;

    padding: 53px 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    width: 85%;

    display: flex;
}

.footerLogo {
    height: 48px;

    margin-right: 96px;
}

.footerText div {
    margin-bottom: 10px;

    font-size: 15px;
    font-family: NanumSquareBold;

    line-height: 22px;

    color: white;
}

.footerText > div:last-child {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    white-space: nowrap;
}

.footerText > div div:first-of-type {
    margin-right: 29px;
}

@media (max-width: 800px) {
    .footerText div {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .footerLogo {
        margin-right: 36px;
    }
    .footerText div {
        font-size: 12.5px;
    }
}
