.introductionWrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

/* introductionHeader 시작 */
.introductionHeader {
  width: 100%;
  height: 513px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url(assets/images/introduction/introduction_header_bg.png) center;
  background-size: cover;

  text-align: center;
  padding: 0 11.72%;
}

.introductionHeader1 {
  font-size: 54px;
  font-family: NanumSquareBold;

  color: white;

  margin-bottom: 27px;
}

.introductionHeader2 {
  font-size: 18px;
  font-family: NanumSquareBold;

  line-height: 32px;

  color: white;
}

/* introductionHeader 끝 */
/* introductionSection1 시작 */

.introductionSection1 {
  width: 100%;

  background: #f9f9f9;

  position: relative;

  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;

  padding: 105px 11.72%;
}

.introductionText {
  width: min(100%, 838px);
}

.introductionText1 {
  font-size: 38px;
  font-family: NanumSquareBold;

  line-height: 54px;

  color: #713dc5;

  margin-bottom: 32px;
}

.introductionText2 {
  font-size: 22px;
  font-family: NanumSquareBold;

  line-height: 39px;
}

.introductionText3 {
  font-size: 20px;
  line-height: 37px;

  margin-top: 31px;
}

.introductionImage {
  width: min(100%, 838px);

  margin: 0 0 92px;
}

.introductionImage img {
  width: 100%;
  height: auto;
}

/* introductionSection1 끝 */
/* introductionSection2 시작 */

.introductionSection2 {
  width: 100%;

  margin: 105px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.introductionSectionTitle {
  width: 100%;

  padding: 0 11.72%;

  font-size: 38px;
  font-family: NanumSquareEB;

  color: #713dc5;
}

.introductionSection2Card {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.introductionSection2LogoWrap {
  width: 294px;
  height: 294px;

  background: rgba(113, 61, 197, 0.15);

  border-radius: 50%;

  margin: 44px 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.introductionSection2Logo {
  width: 246px;
  height: 246px;

  background: white;
  border: 1px solid #713dc5;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  display: flex;
  justify-content: center;
  align-items: center;
}

.introductionSection2Logo img {
  width: auto;
  height: 50px;
}

.introductionSection2Cards {
  width: 1219px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.introductionSection2CardsLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.introductionSection2CardItem {
  width: max(26.57vw, 547px);
  height: 121px;

  background: #f9f9f9;

  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  padding: 25px 23px;

  margin: 15px 19px 15px 19px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.introductionSection2CardTitle {
  width: 156px;

  margin-right: min(6%, 76px);

  font-size: 18px;
  font-family: NanumSquareBold;
  line-height: 32px;

  color: #713dc5;
}

.introductionSection2CardContentsLine {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.introductionSection2CardContentsSpot {
  width: 5px;
  height: 5px;

  border-radius: 50%;

  background: #713dc5;

  margin-right: 7px;
  margin-bottom: 3px;
}

.introductionSection2CardContentsText {
  font-size: 15px;
  line-height: 32px;
}

.introductionSection2Table {
  width: 100%;

  margin: 10px 0 0;
  padding: 0 11.72%;
}

.introductionSection2Table table {
  width: 100%;

  border-top: 1px solid #acacac;
  border-bottom: 1px solid #acacac;
  border-collapse: collapse;

  font-size: 18px;

  line-height: 46px;
}

.introductionSection2Table tr {
  /* height: 46px; */
  border-bottom: 1px solid #acacac;

  display: flex;
  align-items: center;

  padding: 9px 0;
}

.introductionSection2Table tr td:first-child::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;

  margin-bottom: 2px;
  margin-right: 12px;

  background: #713dc5;

  border-radius: 50%;
}

.introductionSection2Table tr td:first-child {
  width: 279px;

  padding-left: 48px;

  font-family: NanumSquareBold;

  color: #713dc5;

  display: flex;
  align-items: center;
}

.introductionSection2Table tr td:last-child {
  width: calc(100% - 279px);
  padding-left: 17px;
}

.introductionSection2Table p {
  font-size: 18px;
  line-height: 36px;
}

.introductionSection2Table p span {
  font-family: NanumSquareBold;
}

.introductionSection2Table p:first-of-type {
  margin-bottom: 18px;
}

/* introductionSection2 끝 */
/* introductionSection4 시작 */
.introductionSection4 {
  width: 100%;

  position: relative;

  background: #ebebeb;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  padding: 105px 11.72% 0;
}

.introductionSection4Title {
  width: 100%;

  margin-bottom: 126px;

  font-size: 38px;
  font-family: NanumSquareEB;
  color: #713dc5;
}

.introductionSection4Contents {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  position: relative;

  padding-bottom: 75px;
}

.introductionSection4Year {
  font-size: 20px;

  color: #6b6b6b;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  z-index: 2;
}

.introductionSection4YearDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 62px;

  transition: 0.2s;
}

.introductionSection4YearDiv > div {
  cursor: pointer;
}

.introductionSection4YearCircle {
  width: 29px;
  height: 29px;

  border-radius: 50%;

  background: rgba(112, 112, 112, 0.56);

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 59px;

  transition: 0.2s;
}

.introductionSection4YearInnerCircle {
  width: 11px;
  height: 11px;

  border-radius: 50%;

  background: #6b6b6b;

  transition: 0.2s;
}

.addDivClass {
  margin-bottom: 120px;

  transition: 0.2s;
}

.addDivClass > .introductionSection4YearText {
  font-family: NanumSquareBold;

  color: black;

  transition: 0.2s;
}

.addDivClass > .introductionSection4YearCircle {
  width: 47px;
  height: 47px;

  background: rgba(113, 61, 197, 0.56);

  margin: 0 47px;

  transition: 0.2s;
}

.addDivClass .introductionSection4YearInnerCircle {
  width: 19px;
  height: 19px;

  background: #713dc5;

  transition: 0.2s;
}

.introductionSection4ContentsLine {
  width: 1px;
  height: calc(100% - 19px);

  background: #aaaaaa;

  position: absolute;
  top: 19px;
  left: 120px;
  z-index: 1;
}

.introductionSection4Text {
  font-size: 20px;
  line-height: 38px;
  background-color: red;
  margin-left: 12px;
  z-index: 2;
}

.introductionSection4Text li {
  margin-bottom: 30px;
}

/* introductionSection4 끝 */

@media (max-width: 800px) {
  .introductionHeader div {
    width: 76.56%;

    text-align: center;
  }

  .introductionHeader1 {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .introductionHeader2 {
    font-size: 12px;
    line-height: 23px;
  }

  .introductionSection1 {
    padding: 105px 50px;
  }

  .introductionImage {
    margin-bottom: 92px;
  }

  .introductionText1 {
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 32px;
  }

  .introductionText3 {
    font-size: 18px;
    line-height: 34px;
    margin-top: 21px;
  }

  .introductionSection2 {
    width: 100%;
  }

  .introductionSectionTitle,
  .introductionSection2Table {
    padding: 0 50px;
  }

  .introductionSection2CardsLine {
    width: 100%;
  }

  .introductionSection2CardItem {
    width: 100%;
    height: 79px;
    padding: 14px 14px;
    margin: 9px 50px;
  }

  .introductionSection2CardTitle {
    width: 103px;
    margin-right: min(5%, 76px);
    font-size: 12px;
    line-height: 18px;
  }

  .introductionSection2CardContentsText {
    font-size: 11px;
    line-height: 19px;
  }

  .introductionSection2Table tr {
    padding: 0 22px;
  }

  .introductionSection2Table tr td:first-child {
    width: 132px;

    padding-left: 0;
    margin-right: min(6%, 76px);

    font-size: 12px;
    line-height: 22px;
  }

  .introductionSection2Table tr td:last-child {
    width: calc(100% - 145px);
    padding-left: 0;
    font-size: 12px;
    line-height: 22px;
  }

  .introductionSection2Table p {
    font-size: 12px;
    line-height: 22px;
  }
  .introductionSection4 {
    padding: 105px 30px;
  }
  .addDivClass > .introductionSection4YearCircle {
    margin: 0 48px;
  }

  .introductionSection4ContentsLine {
    left: 115px;
  }

  .introductionSection4Year {
    font-size: 18px;
  }

  .introductionSection4Text {
    font-size: 16px;
    margin-left: 12px;
    line-height: 28px;
  }

  .introductionSection4Text li {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .introductionSection1 {
    padding: 105px 35px;
  }

  .introductionImage {
    width: 100%;
    margin-bottom: 50px;
  }

  .introductionText {
    width: 100%;
  }

  .introductionText1 {
    font-size: 25px;
    line-height: 42px;
    margin-bottom: 26px;
  }

  .introductionText2 {
    font-size: 15px;
    line-height: 23px;
  }

  .introductionText3 {
    font-size: 12px;
    line-height: 23px;
    margin-top: 18px;
  }

  .introductionSectionTitle {
    font-size: 25px;

    padding: 0 35px;
  }

  .introductionSection2Table {
    padding: 0 35px 0 35px;
  }

  .introductionSection2Table tr {
    padding: 0 14px;
  }

  .introductionSection2LogoWrap {
    width: 191px;
    height: 191px;
  }

  .introductionSection2Logo {
    width: 158px;
    height: 158px;
  }

  .introductionSection2Logo img {
    width: auto;
    height: 35.1px;
  }
  .introductionSection2CardTitle {
    width: 86px;
  }

  .introductionSection2CardItem {
    margin: 9px 10px;
  }

  .introductionSection2Table tr td:first-child {
    width: 99px;
  }

  .introductionSection2Table tr td:last-child {
    width: calc(100% - 99px);
    padding-left: 0;
    font-size: 12px;
    line-height: 22px;
  }

  .introductionSection4 {
    padding: 105px 10px;
  }

  .introductionSection4Title {
    font-size: 25px;
  }

  .introductionSection4ContentsLine {
    left: 82px;
  }

  .introductionSection4Year {
    font-size: 14px;
  }

  .introductionSection4Text {
    font-size: 12px;
    margin-left: 12px;
    line-height: 21px;
  }

  .introductionSection4Text li {
    margin-bottom: 12px;
  }

  .introductionSection4YearCircle {
    width: 25px;
    height: 25px;
    margin: 0 37px;
  }

  .introductionSection4YearInnerCircle {
    width: 11px;
    height: 11px;
  }

  .addDivClass {
    margin-bottom: 120px;
  }

  .addDivClass > .introductionSection4YearCircle {
    width: 36px;
    height: 36px;

    margin: 0 31px;
  }

  .addDivClass .introductionSection4YearInnerCircle {
    width: 14px;
    height: 14px;
  }
}

.speech-bubble {
  position: relative;
  background: red;
  border-radius: 0.4em;
}
.speech-bubble:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: red;
  border-left: 0;

  margin-left: -20px;
}
