.MainSolutionWrap {
    width: 100%;

    background: #f9f9f9;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MainSolutionTitle {
    width: 74.7%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin: 105px 0 89px;
}

.MainSolutionTitleText1 {
    font-size: 38px;
    font-family: NanumSquareEB;

    line-height: 54px;

    color: #703dc5;

    margin-bottom: 16.5px;
}

.MainSolutionTitleText2 {
    font-size: 20px;

    line-height: 37px;
}

.MainSolutionCards {
    width: 88%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-bottom: 105px;
}

.MainSolutionCards > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.MainSolutionCard {
    width: 352px;
    height: 540px;

    border-radius: 13px;

    background: white;

    margin: 19px 12px;
    padding: 31px 36px 42px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background: #ebebeb;

    transition: 0.2s;

    cursor: pointer;
}

.MainSolutionCard:hover {
    background: white;

    box-shadow: 17px 16px 20px rgba(0, 0, 0, 0.05);
    transition: 0.2s;
}

.MainSolutionCardBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.MainSolutionCardImage {
    width: 100%;
    height: 125.86px;

    margin-top: 19px;
}

.MainSolutionCardLogo {
    width: 100%;
    height: 23.16px;

    margin-top: 32px;
}

.MainSolutionCardText {
    width: 100%;

    margin-top: 32.8px;

    font-size: 18px;

    line-height: 35px;
}

@media (max-width: 800px) {
}

@media (max-width: 500px) {
    .MainSolutionCard {
        margin: 12px 0px;
    }

    .MainSolutionTitleText1 {
        font-size: 25px;
    }

    .MainSolutionTitleText2 {
        font-size: 14px;
        line-height: 22px;
    }
}
