.solutionWrap {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* solutionIntro 시작 */

.solutionIntro {
    width: 100%;
    height: max(calc(50.45vw - 89px), 500px);
    /* height: calc(50.45vw - 89px); */

    overflow: hidden;

    position: relative;
}

.solutionSliderWrap {
    width: 400%;
    height: 100%;

    display: flex;
}

.solutionSliderBG {
    width: 100%;
    height: max(calc(50.45vw - 89px), 500px);

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    background: url(assets/images/solution/slider_background.jpg) repeat-y
        center;
    background-size: cover;

    overflow: hidden;

    display: flex;
    justify-content: center;
}

.solutionSliderBG img {
    width: auto;
    height: 100%;
}

.solutionSlider {
    width: 100%;
    height: 100%;

    padding: 0 2.93%;

    font-size: 18px;
    font-family: NanumSquareBold;

    line-height: 32px;

    text-align: center;

    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutionSlider img {
    width: auto;
    height: 5.2vw;

    margin-bottom: 35px;
}

.solutionSliderRemoteWrap {
    width: 100%;

    position: absolute;
    top: 80%;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.solutionSliderRemote {
    width: 76.56%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.solutionSliderCount {
    color: white;
    font-family: NanumSquareBold;

    margin-right: 19px;
}

.solutionSliderCount span {
    color: #f3e727;
    font-family: NanumSquareBold;
}

.solutionSliderBtn {
    display: flex;
    align-items: center;
}

.solutionSliderBtn div {
    width: 26px;
    height: 26px;

    margin-right: 8px;

    cursor: pointer;
}

.solutionSliderDragLine {
    width: 480px;
    height: 1px;

    background: white;
}

.solutionSliderDragbox {
    width: 120px;
    height: 10px;

    background: white;
}

/* solutionSection1 시작 */
.solutionSection1 {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #ebebeb;
}

.solutionSection1Title {
    width: 73.7%;

    margin-top: 170px;
    margin-bottom: 64px;
}

.solutionSection1TitleText1 {
    font-size: 38px;
    font-family: NanumSquareEB;

    line-height: 54px;

    color: #713dc5;

    margin-bottom: 16.5px;
}

.solutionSection1TitleText2 {
    font-size: 20px;
    line-height: 37px;
}

.solutionSection1Items {
    width: 73.7%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutionSection1Item {
    width: 100%;

    background: white;
    border-radius: 13px;
    box-shadow: 17px 16px 20px rgba(0, 0, 0, 0.05);

    padding: 38px 62px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 25px 0;
}

.solutionSection1LeftItem {
    width: 43.6%;

    margin: 0 10px;
}

.solutionSection1ItemLogoArea {
    width: 100%;

    background: #ebebeb;

    border-top: 2px solid #713dc5;
    border-bottom: 2px solid #713dc5;

    padding: 24px 17px 20px;
}

.solutionSection1ItemLogo {
    height: 36px;

    margin-bottom: 17px;
}

.solutionSection1ItemLogo img {
    width: auto;
    height: 100%;
}

.solutionSection1ItemsubtitleText {
    font-size: 23px;
    font-family: NanumSquareBold;

    line-height: 42px;

    color: #713dc5;
}

.solutionSection1ItemExplanation {
    margin-top: 19px;
}

.solutionSection1ItemExplanation
    .solutionSection1ItemExplanationTextWrap:first-of-type {
    border-bottom: 2px solid #aaaaaa;
}

.solutionSection1ItemExplanationTextWrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 17px 0;
}

.solutionSection1ItemExplanationCircle {
    width: 6px;
    height: 6px;

    border-radius: 50%;

    margin: 13px 16px 0 0;

    background: #713dc5;
}

.solutionSection1ItemExplanationText {
    width: 100%;

    font-size: 18px;

    line-height: 35px;
}

.solutionSection1ItemExplanationSubTextWrap {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.solutionSection1ItemExplanationSubText {
    font-size: 12px;

    line-height: 23px;

    color: #7d7d7d;

    /* white-space: nowrap; */
}

.solutionSection1RightItem {
    width: 48.78744650499287%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutionSection1ItemImage {
    width: 100%;
}

.solutionSection1ItemImage img {
    width: 100%;
    height: auto;
}

.solutionSection1ItemBtn {
    width: 100%;
}

.solutionSection1ItemBtn a {
    width: 100%;
    height: 32px;

    border-radius: 16px;

    background: #713dc5;

    font-size: 15px;
    font-family: NanumSquareBold;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 19px;

    cursor: pointer;
}

/* solutionSection2 시작 */

.solutionSection2 {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutionSection2Title {
    width: 73.7%;

    margin-top: 170px;
    margin-bottom: 90px;
}

.solutionSection2TitleText1 {
    font-size: 38px;
    font-family: NanumSquareEB;

    line-height: 54px;

    color: #713dc5;

    margin-bottom: 16.5px;
}

.solutionSection2TitleText2 {
    font-size: 20px;
    line-height: 37px;
}

.solutionSection2Items {
    width: 73.7%;

    margin-bottom: 70px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solutionSection2Item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutionSection2ItemImg {
    height: 393px;

    margin-bottom: 33px;
}

.solutionSection2ItemText {
    font-size: 30px;
    font-family: NanumSquareBold;

    line-height: 48px;
}

/* solutionSection3 시작 */

.solutionSection3 {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutionSection3Title {
    width: 73.7%;

    margin-top: 70px;
    margin-bottom: 65px;
}

.solutionSection3TitleText1 {
    font-size: 38px;
    font-family: NanumSquareEB;

    line-height: 54px;

    color: #713dc5;

    margin-bottom: 16.5px;
}

.solutionSection3Items {
    width: 73.7%;

    margin-bottom: 146px;
    padding: 38px 54px;

    border: 1px solid #aaaaaa;
    border-radius: 13px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solutionSection3Item {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.solutionSection3ItemCategory {
    padding: 7px 19px 6px;
    margin-bottom: 20px;

    border: 2px solid #e04971;
    border-radius: 20px;

    font-size: 21px;
    font-family: NanumSquareBold;
}

.solutionSection3ItemTitle {
    font-size: 23px;
    font-family: NanumSquareBold;

    line-height: 38px;

    margin-bottom: 26px;
}

.solutionSection3ItemSub {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solutionSection3ItemSubText {
    font-size: 21px;

    line-height: 37px;
}

@media (max-width: 1625px) {
    .solutionSection1Item {
        flex-wrap: wrap;
    }
    .solutionSection1LeftItem {
        width: 100%;
    }
    .solutionSection1RightItem {
        width: 100%;
    }

    .solutionSection2Items {
        flex-direction: column;
        justify-content: center;
    }

    .solutionSection2Item {
        margin-bottom: 70px;
    }

    .solutionSection3ItemSub {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 800px) {
    .solutionSliderRemote {
        justify-content: center;
    }

    .solutionSection1Title,
    .solutionSection2Title {
        margin-top: 113px;
        margin-bottom: 35px;
    }

    .solutionSection1Item {
        padding: 38px 13px;
    }
    .solutionSection1ItemLogo {
        height: 18px;
    }
    .solutionSection1ItemExplanation {
        margin-top: 7px;
    }
    .solutionSection1ItemExplanationCircle {
        margin: 8px 8px 0 0;
    }
    .solutionSection1ItemExplanationText {
        font-size: 13px;
        line-height: 22px;
    }
    .solutionSection1ItemsubtitleText {
        font-size: 18px;
        line-height: 29px;
    }
    .solutionSection1ItemExplanationSubText {
        font-size: 10px;
    }

    .solutionSection3ItemSubText {
        font-size: 18px;
        line-height: 30px;
    }
}

@media (max-width: 500px) {
    .solutionSlider {
        font-size: 10px;

        line-height: 18px;
    }
    .solutionSection1Title,
    .solutionSection2Title,
    .solutionSection1Items,
    .solutionSection2Items,
    .solutionSection3Title,
    .solutionSection3Items {
        width: 89.7%;
    }

    .solutionSection1Title {
        margin-top: 74px;
        margin-bottom: 18px;
    }

    .solutionSection2Title {
        margin-top: 74px;
        margin-bottom: 39px;
    }

    .solutionSection3Title {
        margin-top: 10px;
        margin-bottom: 39px;
    }
    .solutionSection1TitleText1,
    .solutionSection2TitleText1,
    .solutionSection3TitleText1 {
        font-size: 25px;
    }
    .solutionSection1TitleText2,
    .solutionSection2TitleText2 {
        font-size: 14px;
        line-height: 22px;
    }

    .solutionSection2Item {
        margin-bottom: 59.4px;
    }

    .solutionSection2ItemImg {
        height: 274px;

        margin-bottom: 28px;
    }

    .solutionSection2ItemText {
        font-size: 26px;
        line-height: 39px;
    }
}
