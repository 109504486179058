.loading-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    background: rgb(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-text {
    font: 1rem "Noto Sans KR";
    text-align: center;
}

@keyframes ldio-vh27y8oc0rc {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.ldio-vh27y8oc0rc div {
    left: 97.97px;
    top: 51.51px;
    position: absolute;
    animation: ldio-vh27y8oc0rc linear 0.8s infinite;
    background: #e34971;
    width: 6.0600000000000005px;
    height: 18.18px;
    border-radius: 3.0300000000000002px / 3.636px;
    transform-origin: 3.0300000000000002px 49.49px;
}
.ldio-vh27y8oc0rc div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.7333333333333333s;
    background: #e34971;
}
.ldio-vh27y8oc0rc div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.6666666666666666s;
    background: #000000;
}
.ldio-vh27y8oc0rc div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.6s;
    background: #f3e727;
}
.ldio-vh27y8oc0rc div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.5333333333333333s;
    background: #5a4591;
}
.ldio-vh27y8oc0rc div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.4666666666666667s;
    background: #e34971;
}
.ldio-vh27y8oc0rc div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.4s;
    background: #000000;
}
.ldio-vh27y8oc0rc div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.3333333333333333s;
    background: #f3e727;
}
.ldio-vh27y8oc0rc div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.26666666666666666s;
    background: #5a4591;
}
.ldio-vh27y8oc0rc div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.2s;
    background: #e34971;
}
.ldio-vh27y8oc0rc div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.13333333333333333s;
    background: #000000;
}
.ldio-vh27y8oc0rc div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.06666666666666667s;
    background: #f3e727;
}
.ldio-vh27y8oc0rc div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #5a4591;
}
.loadingio-spinner-spinner-35gbmmlvkk4 {
    width: 202px;
    height: 202px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-vh27y8oc0rc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-vh27y8oc0rc div {
    box-sizing: content-box;
}
/* generated by https://loading.io/ */
