.MainInfoBG {
    width: 100%;

    background: url(assets/images/main/main_info_bg.png);
    background-size: cover;

    padding: 105px 0;

    display: flex;
    justify-content: center;
    align-items: center;
}
.MainInfoWrap {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.MainInfoText {
    width: 412px;
}

.MainInfoText1 {
    font-size: 38px;
    font-family: NanumSquareEB;

    line-height: 54px;

    color: #713dc5;

    margin-bottom: 33px;
}

.MainInfoText2 {
    font-size: 20px;
    font-family: NanumSquareBold;

    line-height: 37px;

    color: black;

    margin-bottom: 33px;
}

.MainInfoBtn {
    width: 112px;
    height: 40px;

    background: #713dc5;
    border-radius: 3px;

    font-size: 16px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.MainInfoCard {
    width: 426px;
    height: 595px;

    border-radius: 10px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);

    background: #1c1d5b;

    margin: 45px 24px 0;
    padding: 47px 39px 0;
}

.card1 {
    background: url(assets/images/main/main_info_card1_bg.png);
    background-size: cover;
}

.card2 {
    background: url(assets/images/main/main_info_card2_bg.png);
    background-size: cover;
}

.MainInfoCardText {
    font-size: 35px;
    font-family: NanumSquareBold;

    line-height: 55px;

    color: white;
}

@media (max-width: 800px) {
    .MainInfoBG {
        background: none;
    }
}

@media (max-width: 500px) {
    .MainInfoBG {
        padding: 105px 0;
    }

    .MainInfoText {
        width: 279px;
    }

    .MainInfoText1 {
        font-size: 28px;

        line-height: 42px;
    }

    .MainInfoText2 {
        font-size: 12px;

        line-height: 23px;
    }
    .MainInfoCard {
        width: 287px;
        height: 400px;

        padding: 31.7px 39px 0;
    }

    .MainInfoCardText {
        font-size: 22px;

        line-height: 35px;
    }
}
